<template>
  <transition name="fade">
    <div
      class="hero has-background-primary is-fullheight is-bold"
      v-if="showForm"
    >
      <div class="hero-body columns is-centered is-multiline m-0">
        <div class="column is-two-fifths">
          <h1 class=" is-size-1 has-text-centered has-text-light ">
            Welcome to B&B TV
          </h1>
          <div class="box">
            <div class="field">
              <label class="label">First Name</label>
              <div class="control">
                <input
                  class="input"
                  type="text"
                  v-model="first_name"
                  placeholder="First Name"
                />
              </div>
            </div>
            <div class="field">
              <label class="label">Last Name</label>
              <div class="control">
                <input
                  class="input"
                  type="text"
                  v-model="last_name"
                  placeholder="Last Name"
                />
              </div>
            </div>

            <div class="field">
              <label class="label">Email</label>
              <div class="control">
                <input
                  :class="['input-group', isEmailValidClass()]"
                  type="text"
                  v-model="email"
                  placeholder="Email"
                />
              </div>
            </div>

            <div class="field">
              <label class="label">Code</label>
              <div class="control">
                <input
                  class="input"
                  type="text"
                  v-model="code"
                  placeholder="Code"
                />
              </div>
            </div>
            <div class="field has-text-centered">
              <div class="control">
                <button class="button is-link" @click="addTestData">
                  Let's Go!
                </button>
              </div>
              <!-- <div class="control">
                <button class="button is-warning" @click="fetchViewerSingle">
                  Fetch Data Single
                </button>
              </div> -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
  <div v-if="showTimer">
    <h1>{{ date }}</h1>
    <h2>{{ time }}</h2>
  </div>
  <transition name="fadelong">
    <div v-if="showPlayer">
      <div class="watermark">B&B</div>
      <iframe
        :src="url"
        style="position: absolute; top:0;left: 0; width: 100%; height: 100%;"
        frameborder="0"
        allow="autoplay"
        allowfullscreen
      ></iframe>
    </div>
  </transition>
  <!-- <p v-for="month in months" :key="month">{{ month }}</p> -->
  <!-- <img alt="Vue logo" src="./assets/logo.png">
  <HelloWorld msg="Welcome to Your Vue.js App"/> -->
</template>

<script>
import axios from "axios";
export default {
  name: "App",
  data() {
    return {
      showForm: false,
      showTimer: false,
      showPlayer: false,
      location: "",
      time: "",
      date: "",
      months: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ],
      first_name: "",
      last_name: "",
      email: "",
      code: "",
      reg: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/,
      url: "",
    };
  },
  mounted() {
    // let externalScript = document.createElement("script");
    // externalScript.setAttribute("src", "fa1151.js");
    // document.head.appendChild(externalScript);
    if (this.$cookie.isCookieAvailable("bnbkie")) {
      // console.log("cookie time");
      var bnbkie = this.$cookie.getCookie("bnbkie");
      // this.getIP().then((res) => {
      //   this.ip = res.data.ip;
      // });
      this.location = bnbkie.code;
      this.fetchLocSingle().then((res) => {
        this.url = res.data.url;
      });
      this.showForm = false;
      this.showPlayer = true;
      // console.log(typeof this.ip);
    } else {
      this.showForm = true;
      this.showPlayer = false;
    }
  },
  created() {
    // this.getTimeNow();
    // setInterval(this.getTimeNow, 1);
    // this.getDateNow();
    // setInterval(this.getDateNow, 1000);
    console.log(this.$route.query.id);
    this.code = this.$route.query.id;
    this.fetchViewerSinglebyCode().then((response) => {
      this.first_name = response.data.first_name;
      this.last_name = response.data.last_name;
      this.email = response.data.email;
    });
  },
  methods: {
    getIP: function() {
      return axios.get("https://api.ipify.org?format=json");
    },
    isEmailValidClass: function() {
      return this.email == ""
        ? "input"
        : this.reg.test(this.email)
        ? " input is-success"
        : " input is-danger";
    },
    addTestData: function() {
      if (
        this.first_name != "" &&
        this.last_name != "" &&
        this.email != "" &&
        this.code != ""
      ) {
        // console.log(this.ip, "reee");
        if (this.reg.test(this.email)) {
          this.fetchViewerSinglebyCode().then((response) => {
            if (!Array.isArray(response.data)) {
              // console.log(
              //   response.data.date_access == "0000-00-00 00:00:00.000000"
              // );
              if (response.data.date_access == "0000-00-00 00:00:00.000000") {
                this.updateViewerSinglebyCode().then((responsey) => {
                  this.location = response.data.loc_code;
                  this.fetchLocSingle().then((resp) => {
                    this.url = resp.data.url;
                    this.showForm = false;
                    this.showPlayer = true;

                    this.$cookie.removeCookie("bnbkie", {
                      path: "/",
                      domain: "",
                    });

                    var bnbkie = {
                      code: this.location,
                    };
                    this.$cookie.setCookie("bnbkie", bnbkie);
                  });
                });
              } else {
                alert("User already watching");
              }
            } else {
              alert("Enter Valid Code");
            }
          });
        } else {
          alert("Enter Valid Email");
        }

        // this.fetchLocSingle().then((resp) => {
        //   // console.log(resp);
        //   if (!Array.isArray(resp.data)) {
        //     this.url = resp.data.url;
        //     // console.log(this.url);
        //     if (this.reg.test(this.email)) {
        //       // console.log(this.ip);
        //       this.fetchViewerSingle().then((fetchy) => {
        //         // console.log(fetchy.data);
        //         if (Array.isArray(fetchy.data)) {
        //           // console.log(this.ip);
        //           // this.getIP().then((res) => {
        //           //   this.ip = res.data.ip;
        //           // });
        //           axios
        //             .post("action.php", {
        //               action: "insertViewer",
        //               firstName: this.first_name,
        //               lastName: this.last_name,
        //               email: this.email,
        //               code: this.code.toUpperCase(),
        //               ip: this.ip,
        //             })
        //             .then((response) => {
        //               // this.fetchData();
        //               this.first_name = "";
        //               this.last_name = "";
        //               this.email = "";
        //               this.code = "";
        //               // alert(response.data.message);
        //               // console.log(response.data.message);
        //             });
        //           this.showForm = false;
        //           this.showPlayer = true;

        //           this.$cookie.removeCookie("bnbkie", {
        //             path: "/",
        //             domain: "",
        //           });

        //           var bnbkie = {
        //             ip: this.ip,
        //             code: this.code,
        //           };
        //           this.$cookie.setCookie("bnbkie", bnbkie);
        //         } else {
        //           alert(
        //             "User is already logged in!\nContact admin to resolve if you think this is an error."
        //           );
        //         }
        //       });
        //     } else {
        //       alert("Enter Valid Email");
        //     }
        //   } else {
        //     alert("Enter Valid Code");
        //   }
        // });
      } else {
        alert("Fill All Field");
      }
    },
    updateViewerSinglebyCode: function() {
      return axios.post("action.php", {
        action: "updateViewerSinglebyCode",
        firstName: this.first_name,
        lastName: this.last_name,
        email: this.email,
        dateAccess: Math.floor(Date.now() / 1000),
        code: this.code,
      });
    },
    fetchViewerSinglebyCode: function() {
      return axios.post("action.php", {
        action: "fetchSingleViewerbyCode",
        code: this.code,
      });
    },
    fetchViewerSingle: function() {
      return axios.post("action.php", {
        action: "fetchSingleViewer",
        firstName: this.first_name,
        lastName: this.last_name,
        email: this.email,
        code: this.code.toUpperCase(),
      });
    },
    fetchLocSingle: function() {
      return axios.post("action.php", {
        action: "fetchSingleLoc",
        code: this.location.toUpperCase(),
      });
    },

    getTimeNow: function() {
      const today = new Date();
      this.time =
        (today.getHours() % 12).toString().padStart(2, 0) +
        ":" +
        today
          .getMinutes()
          .toString()
          .padStart(2, 0) +
        ":" +
        today
          .getSeconds()
          .toString()
          .padStart(2, 0) +
        "." +
        today
          .getMilliseconds()
          .toString()
          .padStart(3, 0) +
        (today.getHours() < 12 ? " am" : " pm");
    },
    getDateNow: function() {
      const today = new Date();
      this.date =
        today.getFullYear() +
        " " +
        this.months[today.getMonth()] +
        " " +
        today
          .getDate()
          .toString()
          .padStart(2, 0);
    },
  },
};
</script>

<style>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.fadelong-enter-active,
.fadelong-leave-active {
  transition: opacity 1.5s ease;
}

.fadelong-enter-from,
.fadelong-leave-to {
  opacity: 0;
}

.watermark {
  position: fixed;
  top: 45%;
  left: 20%;
  font-family: Brush Script MT, Brush Script Std, cursive;
  font-size: xx-large;
  color: rgba(255, 255, 255, 0.1);
  z-index: 2;
}

/* Hide scrollbar for IE, Edge and Firefox */
body {
  /* background-color: rgba(0, 0, 0, 1); */
  overflow: hidden; /* Hide scrollbars */
  overflow-y: hidden; /* Hide vertical scrollbar */
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
/* Hide scrollbar for Chrome, Safari and Opera */
body::-webkit-scrollbar {
  display: none;
}
</style>
